.fp-email {
    width: 100%;
    background: #EDEDED;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757; 
    padding: 12px 27px;
    border: none;
    margin-top: 25px;
    margin-bottom: 15px;
}

.fp-container {
    padding-bottom: 20px;
}

.fp-form button {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    border: 2px solid #FFB40D;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 20px;
}

.fp-form button.active {
    background: #FFB40D;
}

.fp-alert {
    max-width: 431px;
    margin: 4% auto auto auto;

    background: rgba(255, 24, 24, 0.1);
    border: 1px solid #FF1818;
    box-sizing: border-box;
    border-radius: 10px;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FF1818;
    padding: 22px;
}

.fp-password-intro {
    margin-top: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
}