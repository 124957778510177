.resources {
    padding: 50px;
}

.resourcesMenuItem {
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    float: right;
    margin-right: 3%;
    margin-top: 34px;
}

.resourcesMenuItem.active {
    border-bottom: 3px solid #F7B500;
}

.resourcesTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.resources .rboxs {
    margin-top: 24px;
}

.resources .rbox {
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.rboxInfo {
    font-weight: 600;
    font-size: 16px;
    line-height: 169.34%;
    color: #474747;
}

.rboxImage img {
    max-width: 117px;
}

.resourceFrame {
    width: 100%;
    height: 100vh;
    border: none;
    padding: 10px;
}