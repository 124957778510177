.helpTopicBox {
    padding: 23px 28px;
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    margin-bottom: 26px !important;
    margin-right: 15px;
    width: 45%;
    float: left;
}

.helpTag {
    padding: 2px 20px;
    background: #4F536D;
    border-radius: 50px;
    font-weight: 600;
    font-size: 12px;
    line-height: 169.34%;
    font-variant: small-caps;
    color: #FFFFFF;
    margin-bottom: 10px;
    height: 25px;
    display: inline-block;
}

.helpTips {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #3745A5;
}

.helpTipsText {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

.help-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #3745A5;
}

.help-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 28px;
}

.help-answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 34px;
}

.help-resource-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}

.help-resource-image {
    max-width: 70px;
    margin-right: 18px;
}