.registerpage {
    height: 1024px;
    background-color: #f7f8fa;
}

.halfrow {
    width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.halfrow .login-formcontrol {
    max-width: 160px;
}

p.inreq {
    width: 352px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    line-height: 154.84%;
}

p.inreq span {
    color: #FF1818;
}

select.login-formcontrol {
    background: url(/selar.png) no-repeat right #ededed;
    -webkit-appearance: none;
    background-position-x: 310px;
}


.register-thankyou {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    padding: 20px 30px;
    margin-bottom: 40px;
}

.register-resend {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    padding: 0 5px 40px 5px;
    text-align: center;
}

.reg-text {
    width: 352px;
    display: block;
    margin: 10px auto 0 auto;
    text-decoration: underline;
}

.required-text {
    width: 352px;
    display: block;
    margin: auto;
    color: #575757;
}

.field-error-message {
    display: block;
    width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.field-error {
    border: 1px solid #dc3545;
}