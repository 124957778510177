.doctorTitle {
    font-size: 18px;
    font-weight: 600;
    color: #262626;
}

.doctorIdx {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    background-color: #272a41;

    font-size: 17.5px;
    font-weight: 500;
    color: #ffffff !important;
    text-align: center;
    margin-right: 9px;
    float: left;
    cursor: pointer;
}

.doctorIdx.active {
    background-color: #ffb40d !important;
    color: #000000 !important;
}

.doctorContent {
    font-size: 18px;
    color: #575757;
    margin-top: 4px;
}

.doctorLeft {
    float: left;
    width: 70%;
}

.doctorRight {
    float: right;
    width: 30%;
    border-left: 1px solid lightgray;
    cursor: pointer;
}

.moreInfoBtn2 {
    padding: 30px;
}

.bottomModal .modal-dialog {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    width: 97%;
    overflow: auto;
    max-height: 95vh;
 }  

 .mdiTitle {
    font-size: 22px;
    font-weight: 600;
    color: #262626;
 }
 .mdiSub {
    font-size: 19px;
    color: #575757;
 }

 .midHeader {
    font-size: 16px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
 }

 .mdiText {
    font-size: 18px;
    color: #575757;
    margin-bottom: 24px;
 }

 .mdiText strong {
    font-weight: 600;
 }