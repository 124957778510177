.cr-title {
    margin-top: 23px;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.55;
    color: #575757;
}

.cr-subtitle {
    font-size: 18px;
    line-height: 1.55;
    color: #575757;  
    margin-bottom: 7px;
}

.cr-input {
    width: 298px;
    height: 48px;
    border-radius: 10px;
    border: none;
    background-color: #ededed;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.55;
    color: #575757;
    padding-left: 19px;
    margin-top: -10px;
}

.cr-item {
    padding-left: 60px;
    margin-bottom: 21px !important;
}

a.skipForNow {
    font-weight: bold;
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
    text-decoration: none;
}