.questionBubble {
    border-radius: 12px;
    border: none;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #f7b500;
    margin: 8px auto;
    padding: 19px;

    animation: fadeUp 0.5s;
}

.questionBubble p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
}