@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
.answerBubble {
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #fcfcfc;  
    padding: 19px;
    -webkit-animation: fadeUp 0.5s;
            animation: fadeUp 0.5s;
}

.answerBubble div {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
}

.answerBubbleImage {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: absolute;
    left: -50px;
    bottom: 0;
}

.region-badge-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    position: absolute;
    top: -30px;
    left: 0px;
    right: 0px;
    margin: auto;
    background-color: white;
    border-radius: 32px;
    box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.1);
}

.region-badge-mobile {
    height: 32px;
    width: 32px;
}

@-webkit-keyframes fadeUp {
    0% {
        transform: translateY(100px);
        -webkit-transform: -webkit-translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        -webkit-transform: -webkit-translateY(0);
        opacity: 1;
    }
}

@keyframes fadeUp {
    0% {
        transform: translateY(100px);
        -webkit-transform: -webkit-translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        -webkit-transform: -webkit-translateY(0);
        opacity: 1;
    }
}
.questionBubble {
    border-radius: 12px;
    border: none;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #f7b500;
    margin: 8px auto;
    padding: 19px;

    -webkit-animation: fadeUp 0.5s;

            animation: fadeUp 0.5s;
}

.questionBubble p {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
}
.tagsBubble {
    margin: 0 auto;
    -webkit-animation: fadeUp 0.5s;
            animation: fadeUp 0.5s;
}

.tagBubble {
    min-height: 31px;
    border-radius: 50px;
    border: none;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #4f536d;
    margin-right: 9px;
    margin-bottom: 9px;
    padding: 4px 16px;
    font-size: 14px;
    color: #ffffff;  
    cursor: pointer;
    float: left;
}

.tagBubble .colorStyle {
    color: #f7b500;
}

@-webkit-keyframes fadeDown {
    0% {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: -webkit-translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(200px);
        -webkit-transform: -webkit-translateY(200px);
    }
}

@keyframes fadeDown {
    0% {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: -webkit-translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(200px);
        -webkit-transform: -webkit-translateY(200px);
    }
}
.mapBox2 {
    width: 100%;
    height: 220px;
}
.doctorTitle {
    font-size: 18px;
    font-weight: 600;
    color: #262626;
}

.doctorIdx {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    background-color: #272a41;

    font-size: 17.5px;
    font-weight: 500;
    color: #ffffff !important;
    text-align: center;
    margin-right: 9px;
    float: left;
    cursor: pointer;
}

.doctorIdx.active {
    background-color: #ffb40d !important;
    color: #000000 !important;
}

.doctorContent {
    font-size: 18px;
    color: #575757;
    margin-top: 4px;
}

.doctorLeft {
    float: left;
    width: 70%;
}

.doctorRight {
    float: right;
    width: 30%;
    border-left: 1px solid lightgray;
    cursor: pointer;
}

.moreInfoBtn2 {
    padding: 30px;
}

.bottomModal .modal-dialog {
    position:fixed;
    top:auto;
    right:auto;
    left:auto;
    bottom:0;
    width: 97%;
    overflow: auto;
    max-height: 95vh;
 }  

 .mdiTitle {
    font-size: 22px;
    font-weight: 600;
    color: #262626;
 }
 .mdiSub {
    font-size: 19px;
    color: #575757;
 }

 .midHeader {
    font-size: 16px;
    font-weight: 600;
    color: #262626;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
 }

 .mdiText {
    font-size: 18px;
    color: #575757;
    margin-bottom: 24px;
 }

 .mdiText strong {
    font-weight: 600;
 }
.diApply {
    font-size: 16px;
    line-height: 1.3;
    color: #474747; 
    margin-top: 7px;
    margin-bottom: 13px; 
}

.diReadMore {
    cursor: pointer;
    font-size: 19px;
    line-height: 1.25;
    color: #346dff;
}

.diRel {
    font-size: 19px;
    font-weight: 600;
    line-height: 1.69;
    color: #474747;
    margin-top: 7px;
    margin-bottom: 7px;
}

.diType {
    color: #ffffff;
    padding: 5px 20px;
    border: none;
    border-radius: 30px;
    font-weight: bold;
}
.mobile-help-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FFB40D;
}

.mobile-help-desc {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FCFCFC;
    margin-bottom: 38px;
}

.mobile-help-answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FCFCFC;
    margin-bottom: 34px;
}

.mobile-help-resource-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
    width: 160px;
}

.mobile-help-close {
    background: #4E5373;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.16);
    border: none;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    margin-bottom: 18px;
}

.mobile-help-close i {
    color: #F7B500;
    font-size: 24px;
}

.mobile-help-resource-image {
    border-radius: 6px;
    max-width: 70px;
    margin-right: 18px;
}

.mobile-help-box {
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 27px;
}
.chatArea {
    position: relative;
    background-color: #272a41;
}

.chatLogo {
    width: 96px;
    height: 34.3px;
    object-fit: contain; 
    max-width: 100%;
    margin-left: 20px;
}

.tali-help {
    font-size: 16px;
    line-height: 1.17;
    color: #ffffff;
    text-align: center;
    width: 60%;
    margin: 0 auto 10% auto;
}

.mobileBtn button {
    border: none !important;
    background-color: transparent !important;
}

.mobileBtn button:focus {
    box-shadow: none !important;
}

.tali-photo-big {
    width: 102px;
    height: 102px;
    display: block;
    margin: 0 auto 10px auto;
}

.fullpage .tali-intro {
    font-size: 21px;
}

.tali-intro {
    text-align: center;
    font-weight: bold;
    line-height: 1.41;
    color: #e3e3e3;  
    font-size: 18px;
}

.tali-intro .colorStyle {
    color: #ffb40d;
}

.chatField::-webkit-input-placeholder {
    font-size: 16px;
    color: #ffffff;
}

.chatField::-moz-placeholder {
    font-size: 16px;
    color: #ffffff;
}

.chatField:-ms-input-placeholder {
    font-size: 16px;
    color: #ffffff;
}

.chatField::-ms-input-placeholder {
    font-size: 16px;
    color: #ffffff;
}

.chatField::placeholder {
    font-size: 16px;
    color: #ffffff;
}

.chatField {
    height: 53px;
    border-radius: 28.5px;
    border: none;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #4f536d;
    font-size: 16px;
    color: #ffffff;
    padding-left: 33px;
}

.chatField.speaking {
    border: solid 1px #f7b500;
}

.chatField.speaking::-webkit-input-placeholder {
    color: #f7b500;
}

.chatField.speaking::-moz-placeholder {
    color: #f7b500;
}

.chatField.speaking:-ms-input-placeholder {
    color: #f7b500;
}

.chatField.speaking::-ms-input-placeholder {
    color: #f7b500;
}

.chatField.speaking::placeholder {
    color: #f7b500;
}

.chatField.filled {
    background-color: #ffffff;
    color: black;
}

.leftSide {
    width: 90% !important;
}

.sendBtn {
    max-width: 100%;
    cursor: pointer;
}

.chatLis {
    display: block;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
    list-style: none;
}

.chatLis li {
    display: block;
    margin-bottom: 16px;
    margin: 0 auto;
}

.chatLisDiv {
    position: relative;
    margin: 0 auto;
}

.helpIcon {
    z-index: 1000;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: block;
    background: #F7B500;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    float: right;
    margin: 10px;
}

.helpIcon::after {
    content: '?';
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #785801;
    -webkit-transform: translateY(7px);
            transform: translateY(7px);
}

.helping {
    background: #4E5373 !important;
}

.helping::after {
    color: #F7B500 !important;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


/* width */
ul.chatLis::-webkit-scrollbar {
    width: 10px;
}

/* Track */
ul.chatLis::-webkit-scrollbar-track {
    background:  #272a41;
}

/* Handle */
ul.chatLis::-webkit-scrollbar-thumb {
    background: #4f536d;
}

/* Handle on hover */
ul.chatLis::-webkit-scrollbar-thumb:hover {
    background:#f7b500;
}

ul.chatLis {
    scrollbar-color: #272a41 #4f536d;
}

.stickMe {
    position: fixed;
    width: 30%;
    box-shadow: 9px 0 48px 0 rgba(0, 0, 0, 0.14);
    background-color: #272a41;
    height: 100vh;
}

.change-link {
    cursor: pointer;
    text-decoration: underline;
}

.dropdownHeader, .regionListItem, .sourceListItem {
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
}

.dropdownHeader {
    padding-top: 25px;
    padding-bottom: 25px;
}

.dropdownList {
    list-style-type: none;
    padding: 0px;
}

.regionListItem {
    height: 56px;
    cursor: pointer;
}

.sourceListItem {
    height: 70px;
}

.regionListItem:hover {
    background-color: #F7B500;
    color: #000000;
}

.regionText {
    margin: 0;
    font-size: 16px;
}

.sourceContainer .source-info, .sourceContainer .source-logo {
    height: 100%;
    margin: 0;
}

.sourceContainer .source-logo {
    margin-right: 18px;
}

.sourceContainer .sourceListItem {
    padding-bottom: 10px;
    margin: 0;
}

.sourceContainer .custom-control-input {
    position: inherit;
}

.sourceSubmitBtn {
    height: 56px;
    width: 90%;
    background-color: #F7B500;
    font-weight: bold;
    font-size: 20px;
    border: 0;
    margin: auto;
    display: block;
    border-radius: 10px;
    color: #575757;
    margin-bottom: 20px;
}

.sourceContainer .custom-control-label::before {
    background-color: rgb(119, 119, 119) !important;
    border: 0 !important; 
}

.sourceContainer .custom-control-input:checked~.custom-control-label::before {
    background-color: rgba(247, 181, 0, 0.29) !important;
    border: 0 !important;
}
.sourceContainer .custom-switch .custom-control-label::after {
    background-color: #dddddd !important;
}

.sourceContainer .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #F7B500 !important;
}

.mobile-menu {
    float: right;
    list-style: none;
    margin-right: 20px;
}

.mobile-menu li {
    float: right;
}

.mobile-menu .btn-region {
    background: rgba(247, 248, 250, 0.15);
    border-radius: 8px !important;
    color: #ffffff !important;
}

.dropdownHeader span {
    cursor: pointer;
}

.regionListItem:hover, .regionListItem:active, .regionListItem .active {
    background: #F7B500;
}
.drsBoxs {
    padding-right: 10px;
    overflow-y: auto;
    list-style: none;
    max-height: 100%;
}

.drsIntroDiv {
    padding-left: 50px;
    padding-top: 25px;
}

.drsDiv {
    padding-right: 0 !important;
    height: calc(100vh - 200px);
}

.drBox {
    position: relative;
    border: none;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015), -4px 0px 0px #D0D2DF;
    background-color: #ffffff;  
    float: left;
    margin-right: 19px;
    margin-left: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
}

.drBox.highLight {
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 0 7.1px 10px 0 rgba(0, 0, 0, 0.01), -4px 0 0 0 #ffb40d !important;
}

.drBox1 {
    padding: 20px 24px 10px 24px;
}

.drName {
    font-size: 16px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 7px;
}

.drStreet {
    font-size: 13px;
    color: #575757;
    width: 144px;
    margin-bottom: 0;
}

.mapBox {
    width: 100%;
    height: 100%;
}

.moreInfoBtn {
    font-size: 14px !important;
    line-height: 124.84% !important;
    font-feature-settings: "c2sc" !important;
    font-variant: small-caps !important;
    color: #346DFF !important;
    padding: 12px !important;
}

.doctorInfoModal {
    width: 50% !important;
    height: 55% !important;
}

.drModalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #474747;
    margin: 10px;
}

.drModalTabs {
    list-style: none;
    font-size: 14px;
    line-height: 17px;
    font-feature-settings: "c2sc";
    font-variant: small-caps;
    color: #000000;
    border-bottom: 1px solid #E2E2E2;
    height: 28px;
    padding-left: 0;
    margin-left: 10px;
    margin-top: 30px;
}

.drModalTabs li {
    float: left;
    margin-right: 23px;
    padding-bottom: 9px;
    cursor: pointer;
}

.drModalTabs li.active {
    border-bottom: 3px solid #F7B500;
}

.drModalDetail {
    margin-top: 15px;
    margin-left: 10px;
}

.drBoxNumber {
    position: absolute;
    left: -30px;
    display: block;
    width: 25px;
    height: 25px;
    color: #ffffff;
    background-color: #272a41;
    border: none;
    border-radius: 50%;
    text-align: center;
}

.drBox.highLight .drBoxNumber {
    background-color: #ffb40d;
    color: #000000;
}
.youAsked {
    font-size: 10px;
    font-weight: bold;
    color: #a1a1a1;
    margin-top: 40px;
}

.lsisQuestion {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 24px;
}

.lsiTaken {
    font-size: 18px;
    line-height: 1.25;
    color: #212529;  
}

.lsiText {
    white-space: pre-line;
    word-break: break-word;
}

.phalogo {
    max-width: 155px;
}

.lsiCanim {
    font-size: 12px;
    font-weight: 600;
    line-height: 0.97;
    color: #000000; 
    margin-left: 14px; 
    display: inline-block;
}

.row.no-gutters {
    align-items: center;
}

.lsisBox {
    padding: 50px 60px;
    background-color: #f7f8fa;
}

.lsisAnswersDiv {
    margin-top: 48px ;
}

.lsiAnswer {
    position: relative;
    margin: 32px 0px;
}

.region-badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: white;
    border-radius: 32px;
    box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.1);
}

.region-badge {
    height: 45px;
}

.lsiAnswerBox {
    width: 75%;
    max-width: 700px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 5px 11.1px 16px 0 rgba(0, 0, 0, 0.09), -4px 0 0 0 #ffb40d;
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 24px;
    margin-left: 5px;
}

.lsisAnswerBox b {
    font-weight: 600;
    font-style: normal;
}

.lsiAnswerBoxContent, .feedBackBox {
    padding-left: 40px;
    padding-right: 40px;
}

.lsiAnswerBoxContent {
    margin-bottom: 30px;
}

.readMore {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25;
    color: #346dff !important;  
    padding: 0;
    background: none;
    border: none;
}

.feedBackBox {
    background-color: #FFEBB4;
    padding-top: 16px;
    padding-bottom: 16px;
}

.usefulText {
    font-weight: bold;
    font-size: 16px;
}

.feedBackBoxTag {
    font-size: 11px;
    color: #636363;
    margin-bottom: 5px;
}

.feedBackBtn {
    width: 74px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    line-height: 1.55;
    margin-left: 8px;
}

.feedBackBtn, .closeBtn {
    cursor: pointer;
}

.highlight {
    padding: 10px 20px;
    background-color: #FFF1A8;
    border-radius: 10px;
    display: block;
}

.answerModal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: -webkit-translate(-50%, -50%);
    width: 70%;
    height: 90%;
    border-radius: 10px;
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 0 7.1px 10px 0 rgba(0, 0, 0, 0.01);
    background-color: #ffffff;
    -webkit-animation: modal 0.3s;
            animation: modal 0.3s;
}

.modalContent {
    width: 100%;
    height: 70%;
    padding: 20px 30px;
    margin: 10px auto;
    overflow: auto;
}

.closeBtn {
    position: absolute;
    top: 0;
    right: -70px;
}

.modalBackDrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2500;
}

.modalButtons {
    position: absolute;
    bottom: 0;
    right: -70px;
}

.answerModal .feedBackBoxTag {
    font-size: 11px;
    color: #ffffff;  
}

.hideModal {
    -webkit-animation: hidemodal 0.3s;
            animation: hidemodal 0.3s;
}

.hideInfoModal {
    -webkit-animation: hideInfoModal 0.3s;
            animation: hideInfoModal 0.3s;
}

@-webkit-keyframes hideInfoModal {
    0% {
        opacity: 1;
        width: 50%;
        height: 55%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@keyframes hideInfoModal {
    0% {
        opacity: 1;
        width: 50%;
        height: 55%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@-webkit-keyframes hidemodal {
    0% {
        opacity: 1;
        width: 70%;
        height: 90%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@keyframes hidemodal {
    0% {
        opacity: 1;
        width: 70%;
        height: 90%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@-webkit-keyframes modal {
    0% {
        opacity: 0;
        width: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        width: 70%;
        height: 90%;
    }
}

@keyframes modal {
    0% {
        opacity: 0;
        width: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        width: 70%;
        height: 90%;
    }
}

/* width */
.lsisAnswersDiv::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.lsisAnswersDiv::-webkit-scrollbar-track {
    background:  #272a41;
}

/* Handle */
.lsisAnswersDiv::-webkit-scrollbar-thumb {
    background: #4f536d;
}

/* Handle on hover */
.lsisAnswersDiv::-webkit-scrollbar-thumb:hover {
    background:#f7b500;
}

.lsisAnswersDiv {
    scrollbar-color: #272a41 #4f536d;
}

/* width */
.modalContent::-webkit-scrollbar {
    width: 10px;
    display:block;
    margin-right: 50px;
}

/* Track */
.modalContent::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.modalContent::-webkit-scrollbar-thumb {
    background: #d9d9d9;
}

/* Handle on hover */
.modalContent::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
}

.modalContent {
    scrollbar-color:#ffffff #d9d9d9;
}

.lsiToolTipe {
    position: fixed;
    z-index: 3500;
}

.alert-box {
    position: relative;
    background: #EBF1F8;
    border: 1px solid #008BF5;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 25px;
    max-width: 700px;
    font-size: 18px;
    line-height: 21px;
    color: #0070C6;
}

.alert-box label {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #0070C6;
}

.alert-close {
    cursor: pointer;
}

.modalHeaderTaken {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #A2A2A2;
}

.modalQuestion {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 20px 30px;
}

.modalSourceButton {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 154.84%;
    color: #346DFF;
    border: 2px solid #346DFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 15px;
}

.modalSourceButtonPos {
    position: absolute;
    right: 2%;
}
.resources {
    padding: 50px;
}

.resourcesMenuItem {
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    float: right;
    margin-right: 3%;
    margin-top: 34px;
}

.resourcesMenuItem.active {
    border-bottom: 3px solid #F7B500;
}

.resourcesTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
}

.resources .rboxs {
    margin-top: 24px;
}

.resources .rbox {
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.rboxInfo {
    font-weight: 600;
    font-size: 16px;
    line-height: 169.34%;
    color: #474747;
}

.rboxImage img {
    max-width: 117px;
}

.resourceFrame {
    width: 100%;
    height: 100vh;
    border: none;
    padding: 10px;
}
.diAnswerDiv {
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    margin-bottom: 15px; 
}

.diAnswer {
    padding: 28px 28px 0 28px;
}

.diAnswerLabel {
    font-weight: 600;
    font-size: 12px;
    line-height: 169.34%;
    font-feature-settings: "c2sc";
    font-variant: small-caps;
    color: #FFFFFF;
    border-radius: 50px;
    display: inline-block;
    padding: 0px 10px;
}

.diAnswerTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 169.34%;
    color: #474747;
    margin-bottom: 10px;
}

.diAnswerEx {
    font-size: 14px;
    line-height: 130.34%;
    color: #474747;
    margin-bottom: 10px;
}

.diHr {
    color: #E2E2E2;
}

.diAnswer .btn {
    font-size: 14px !important;
    line-height: 124.84% !important;
    font-feature-settings: "c2sc" !important;
    font-variant: small-caps !important;
    color: #346DFF !important;
    padding: 0;
}

.diAnswerDiv .diSource {
    color: #575757;
    font-weight: 600;
    font-size: 14px;
    line-height: 124.84%;
    margin-left: 28px;
    padding-bottom: 16px;
}

.diDiv {
    padding-left: 50px;
    padding-right: 50px;
}

.diRefs {
    font-size: 16px;
}
.dosageModal {
    height: 95%;
}

.dosageModal div {
    white-space: pre-line;
}
.helpTopicBox {
    padding: 23px 28px;
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    margin-bottom: 26px !important;
    margin-right: 15px;
    width: 45%;
    float: left;
}

.helpTag {
    padding: 2px 20px;
    background: #4F536D;
    border-radius: 50px;
    font-weight: 600;
    font-size: 12px;
    line-height: 169.34%;
    font-feature-settings: "c2sc";
    font-variant: small-caps;
    color: #FFFFFF;
    margin-bottom: 10px;
    height: 25px;
    display: inline-block;
}

.helpTips {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #3745A5;
}

.helpTipsText {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

.help-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #3745A5;
}

.help-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 28px;
}

.help-answer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 34px;
}

.help-resource-title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
}

.help-resource-image {
    max-width: 70px;
    margin-right: 18px;
}
.regionMenuItem {
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    float: right;
    margin-right: 3%;
    margin-top: 34px;
}

.regionMenuItem .dropdown-toggle::after { 
    content: none; 
} 

.regionMenuItem .dropdown-header {
    color: black;
    font-weight: bolder;
    margin-right: 200px;
}

.regionMenuItem .dropdown-menu {
    box-shadow: 0px 0px 20px rgba(211, 211, 211, 0.781);
    padding-bottom: 0px;
    border-radius: 8px;
}

.globalRegionsBadge {
    height: 16px;
    margin-right: 12px;
}

.globalRegions.dropdown-item {
    display: flex;
    align-items: center;
}
.globalRegionsContainer {
    border: 1px solid #F0F0F0;
    border-radius: 8px;
    padding: 8px 0px;
    box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.15);
}

.canadianRegions {
    max-height: 200px;
    overflow-y: auto;

}
.resultArea {
    width: 70%;
    float: right;
    background-color: #f7f8fa;
}

.resultHeader {
    height: 90px;
    box-shadow: 0 1.7px 2.2px 0 rgba(125, 125, 125, 0.02), 0 4px 5.3px 0 rgba(125, 125, 125, 0.03), 0 7.5px 10px 0 rgba(125, 125, 125, 0.04), 0 13.4px 17.9px 0 rgba(125, 125, 125, 0.04);
    background-color: #ffffff;
}

.resultLogo {
    margin-top: 28px;
    margin-left: 68px;
    width: 96px;
    height: 34px;  
}

.logOut {
    height: 21px;
    font-size: 18px;
    cursor: pointer;
    float: right;
    margin-right: 63px;
    margin-top: 34px;
}

.logOut a {
    font-weight: bold;
    color: #575862;
}

.circleUser {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #9e71ff;
    border: none;
    border-radius: 50%;
    margin-right: 6px;
    transform: translateY(2px);
    -webkit-transform: -webkit-translateY(4px);
}

.userBox {
    float: right;
    margin-right: 3%;
    margin-top: 34px;
}

.userName {
    height: 25px;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #575862;
    border: none;
    background: none;
}

.userName:focus {
    outline: none;
}

.resourcesMenuItem .dropdown-toggle::after { 
    content: none; 
} 

.resourcesMenuItem .dropdown-header {
    color: black;
    font-weight: bolder;
}

.resourcesMenuItem .dropdown-menu {
    box-shadow: 0px 0px 20px rgba(211, 211, 211, 0.781);
}

.resourcesMenuItem .source-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    padding: 6px;
}

.resourcesMenuItem .source-logo {
    height: 70px;
    width: 70px;
    margin-right: 12px;
}

.source-info {
    display: flex;
    margin: 8px 12px;
    align-items: center;
}

.sources-menu {
    max-height: 250px;
    min-width: 350px;
    overflow-y: scroll;
}
.loginpage {
    height: 1024px;
    background-color: #f7f8fa;
}

.login-header {
    width: 100%;
    height: 90px;
    box-shadow: 0 1.7px 2.2px 0 rgba(125, 125, 125, 0.02), 0 4px 5.3px 0 rgba(125, 125, 125, 0.03), 0 7.5px 10px 0 rgba(125, 125, 125, 0.04), 0 13.4px 17.9px 0 rgba(125, 125, 125, 0.04);
    background-color: #ffffff;
}

.login-header .Logo {
    width: 96px;
    height: 34px;
    object-fit: contain;  
}

.login-form {
    max-width: 431px;
    border-radius: 10px;
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 0 7.1px 10px 0 rgba(0, 0, 0, 0.01);
    background-color: #ffffff; 
    margin: 4% auto auto auto;
}

.loginform-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.55;
    color: #575757;  
}

.login-divider {
    height: 1px;
    color: #e2e2e2;
    margin: 0;
}

.login-formcontrol {
    width: 352px;
    height: 56px;
    border-radius: 10px;
    background-color: #ededed !important;  
    border: none;
    display: block;
    margin: auto;
    font-size: 20px;
    line-height: 1.55;
    color: #575757 !important;
    padding-left: 27px;
}

.login-checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 16px;
    line-height: 1.55;
    color: #575757;  
}


.login-checkbox-container input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    width: 19px;
    height: 19px;
    border-radius: 4px;
    border: solid 2px #575757;
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.login-checkbox-container input:checked ~ .checkmark {
    background-color: #575757;
}

.login-checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
  
.login-checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.loginbtn {
    width: 352px;
    height: 56px;
    border-radius: 10px;
    border: none;
    background-color: #ffb40d;

    font-size: 20px;
    font-weight: bold;
    line-height: 1.55;
    color: #575757;  
    text-align: center;

    display: block;
    margin: auto;
}

.formlegal {
    padding: 0 40px 20px 40px;
}

.formlegal p, .formlegal span {
    font-size: 16px;
    line-height: 1.55;
    color: #575757;
}

.formlegal p b, .formlegal span strong {
    font-weight: bold;
}

.login-form .fieldError {
    width: 352px;
    margin: auto;
    color: red;
}

.login-form .hasError {
    border: 1px solid red !important;
}
.registerpage {
    height: 1024px;
    background-color: #f7f8fa;
}

.halfrow {
    width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.halfrow .login-formcontrol {
    max-width: 160px;
}

p.inreq {
    width: 352px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    line-height: 154.84%;
}

p.inreq span {
    color: #FF1818;
}

select.login-formcontrol {
    background: url(/selar.png) no-repeat right #ededed;
    -webkit-appearance: none;
    background-position-x: 310px;
}


.register-thankyou {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    padding: 20px 30px;
    margin-bottom: 40px;
}

.register-resend {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    padding: 0 5px 40px 5px;
    text-align: center;
}

.reg-text {
    width: 352px;
    display: block;
    margin: 10px auto 0 auto;
    text-decoration: underline;
}

.required-text {
    width: 352px;
    display: block;
    margin: auto;
    color: #575757;
}

.field-error-message {
    display: block;
    width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.field-error {
    border: 1px solid #dc3545;
}
.resend-txt {
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    padding: 20px 30px 0px 30px;
} 

.resend-txt-2 {
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
    padding: 10px 30px 30px 30px;
} 

.resend-txt-link {
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
}

.fp-email {
    width: 100%;
    background: #EDEDED;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757; 
    padding: 12px 27px;
    border: none;
    margin-top: 25px;
    margin-bottom: 15px;
}

.fp-container {
    padding-bottom: 20px;
}

.fp-form button {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    border: 2px solid #FFB40D;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 20px;
}

.fp-form button.active {
    background: #FFB40D;
}

.fp-alert {
    max-width: 431px;
    margin: 4% auto auto auto;

    background: rgba(255, 24, 24, 0.1);
    border: 1px solid #FF1818;
    box-sizing: border-box;
    border-radius: 10px;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #FF1818;
    padding: 22px;
}

.fp-password-intro {
    margin-top: 14px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
}
.cr-title {
    margin-top: 23px;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.55;
    color: #575757;
}

.cr-subtitle {
    font-size: 18px;
    line-height: 1.55;
    color: #575757;  
    margin-bottom: 7px;
}

.cr-input {
    width: 298px;
    height: 48px;
    border-radius: 10px;
    border: none;
    background-color: #ededed;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.55;
    color: #575757;
    padding-left: 19px;
    margin-top: -10px;
}

.cr-item {
    padding-left: 60px;
    margin-bottom: 21px !important;
}

a.skipForNow {
    font-weight: bold;
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
    text-decoration: none;
}
.regionPage {
    width: 100%;
    height: 100vh;
    background-color: #272A41;
}

.regionPage .tali-photo-big {
    margin-bottom: 30px;
}

.regionPage .tali-intro {
    margin-bottom: 39px;
}

.regionBox {
    width: 400px;
    display: block;
    margin: auto;
    margin-top: 5%;
}

@media only screen and (max-width: 540px) {
    .regionBox {
        width: 95%;
    }
}

.regionPageLogo {
    margin-top: 1%;
    margin-left: 2%;
}

.regionSelectInput {
    width: 100%;
    background: #F7F7FA;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 8px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #272A41;
    padding: 10px 16px;

    /* reset */
    -webkit-appearance: none;
    -moz-appearance: none;

    /* add arrow back */
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAARCAYAAAAhUad0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBxc3BDYAgDAXQjuAIjuAIjuImdAPdADZxFEZwBOwBkqYJAbTVn/wDBfoAfs5EXcE2C3Xmg0hNVA828Xl/5MMrDy1gx3YnfrGJCy1YgigfbMpwE9SGu8ESfAkPgzV47/z3GKzBDozBUVgN7IXVwRZsBtbgU5wPMAoKqDSAcfBrUMIBBnMDxWVoq1Tn6j0AAAAASUVORK5CYII=);
    background-position: calc(100% - 16px) center;
    background-size: 16px 9px;
    background-repeat: no-repeat;
}

.regionSelectInput option:hover {
    background-color: #FFB40D;
}

.regionPageText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.85);

    margin-top: 15px;
    margin-bottom: 60px;
}

.saveRegionBtn {
    background: #FFB40D;
    border-radius: 10px;
    border: none;
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 154.84%;
    text-align: center;
    color: #575757;
    padding: 12px;

    margin-bottom: 30px;
}

.regionSkip {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}
*:focus {
    outline: none;
}

html, body {
    background-color: #272a41;
}

.row {
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 19px;
}

h4 {
    font-size: 18px;
}
