.youAsked {
    font-size: 10px;
    font-weight: bold;
    color: #a1a1a1;
    margin-top: 40px;
}

.lsisQuestion {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 24px;
}

.lsiTaken {
    font-size: 18px;
    line-height: 1.25;
    color: #212529;  
}

.lsiText {
    white-space: pre-line;
    word-break: break-word;
}

.phalogo {
    max-width: 155px;
}

.lsiCanim {
    font-size: 12px;
    font-weight: 600;
    line-height: 0.97;
    color: #000000; 
    margin-left: 14px; 
    display: inline-block;
}

.row.no-gutters {
    align-items: center;
}

.lsisBox {
    padding: 50px 60px;
    background-color: #f7f8fa;
}

.lsisAnswersDiv {
    margin-top: 48px ;
}

.lsiAnswer {
    position: relative;
    margin: 32px 0px;
}

.region-badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    position: absolute;
    top: -20px;
    left: -20px;
    background-color: white;
    border-radius: 32px;
    box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.1);
}

.region-badge {
    height: 45px;
}

.lsiAnswerBox {
    width: 75%;
    max-width: 700px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 5px 11.1px 16px 0 rgba(0, 0, 0, 0.09), -4px 0 0 0 #ffb40d;
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: 24px;
    margin-left: 5px;
}

.lsisAnswerBox b {
    font-weight: 600;
    font-style: normal;
}

.lsiAnswerBoxContent, .feedBackBox {
    padding-left: 40px;
    padding-right: 40px;
}

.lsiAnswerBoxContent {
    margin-bottom: 30px;
}

.readMore {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25;
    color: #346dff !important;  
    padding: 0;
    background: none;
    border: none;
}

.feedBackBox {
    background-color: #FFEBB4;
    padding-top: 16px;
    padding-bottom: 16px;
}

.usefulText {
    font-weight: bold;
    font-size: 16px;
}

.feedBackBoxTag {
    font-size: 11px;
    color: #636363;
    margin-bottom: 5px;
}

.feedBackBtn {
    width: 74px;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    line-height: 1.55;
    margin-left: 8px;
}

.feedBackBtn, .closeBtn {
    cursor: pointer;
}

.highlight {
    padding: 10px 20px;
    background-color: #FFF1A8;
    border-radius: 10px;
    display: block;
}

.answerModal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: -webkit-translate(-50%, -50%);
    width: 70%;
    height: 90%;
    border-radius: 10px;
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 0 7.1px 10px 0 rgba(0, 0, 0, 0.01);
    background-color: #ffffff;
    animation: modal 0.3s;
}

.modalContent {
    width: 100%;
    height: 70%;
    padding: 20px 30px;
    margin: 10px auto;
    overflow: auto;
}

.closeBtn {
    position: absolute;
    top: 0;
    right: -70px;
}

.modalBackDrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2500;
}

.modalButtons {
    position: absolute;
    bottom: 0;
    right: -70px;
}

.answerModal .feedBackBoxTag {
    font-size: 11px;
    color: #ffffff;  
}

.hideModal {
    animation: hidemodal 0.3s;
}

.hideInfoModal {
    animation: hideInfoModal 0.3s;
}

@keyframes hideInfoModal {
    0% {
        opacity: 1;
        width: 50%;
        height: 55%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@keyframes hidemodal {
    0% {
        opacity: 1;
        width: 70%;
        height: 90%;
    }

    100% {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

@keyframes modal {
    0% {
        opacity: 0;
        width: 0;
        height: 0;
    }

    100% {
        opacity: 1;
        width: 70%;
        height: 90%;
    }
}

/* width */
.lsisAnswersDiv::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.lsisAnswersDiv::-webkit-scrollbar-track {
    background:  #272a41;
}

/* Handle */
.lsisAnswersDiv::-webkit-scrollbar-thumb {
    background: #4f536d;
}

/* Handle on hover */
.lsisAnswersDiv::-webkit-scrollbar-thumb:hover {
    background:#f7b500;
}

.lsisAnswersDiv {
    scrollbar-color: #272a41 #4f536d;
}

/* width */
.modalContent::-webkit-scrollbar {
    width: 10px;
    display:block;
    margin-right: 50px;
}

/* Track */
.modalContent::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
.modalContent::-webkit-scrollbar-thumb {
    background: #d9d9d9;
}

/* Handle on hover */
.modalContent::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
}

.modalContent {
    scrollbar-color:#ffffff #d9d9d9;
}

.lsiToolTipe {
    position: fixed;
    z-index: 3500;
}

.alert-box {
    position: relative;
    background: #EBF1F8;
    border: 1px solid #008BF5;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 25px;
    max-width: 700px;
    font-size: 18px;
    line-height: 21px;
    color: #0070C6;
}

.alert-box label {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #0070C6;
}

.alert-close {
    cursor: pointer;
}

.modalHeaderTaken {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #A2A2A2;
}

.modalQuestion {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 20px 30px;
}

.modalSourceButton {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 154.84%;
    color: #346DFF;
    border: 2px solid #346DFF;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 5px 15px;
}

.modalSourceButtonPos {
    position: absolute;
    right: 2%;
}