.chatArea {
    position: relative;
    background-color: #272a41;
}

.chatLogo {
    width: 96px;
    height: 34.3px;
    object-fit: contain; 
    max-width: 100%;
    margin-left: 20px;
}

.tali-help {
    font-size: 16px;
    line-height: 1.17;
    color: #ffffff;
    text-align: center;
    width: 60%;
    margin: 0 auto 10% auto;
}

.mobileBtn button {
    border: none !important;
    background-color: transparent !important;
}

.mobileBtn button:focus {
    box-shadow: none !important;
}

.tali-photo-big {
    width: 102px;
    height: 102px;
    display: block;
    margin: 0 auto 10px auto;
}

.fullpage .tali-intro {
    font-size: 21px;
}

.tali-intro {
    text-align: center;
    font-weight: bold;
    line-height: 1.41;
    color: #e3e3e3;  
    font-size: 18px;
}

.tali-intro .colorStyle {
    color: #ffb40d;
}

.chatField::placeholder {
    font-size: 16px;
    color: #ffffff;
}

.chatField {
    height: 53px;
    border-radius: 28.5px;
    border: none;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #4f536d;
    font-size: 16px;
    color: #ffffff;
    padding-left: 33px;
}

.chatField.speaking {
    border: solid 1px #f7b500;
}

.chatField.speaking::placeholder {
    color: #f7b500;
}

.chatField.filled {
    background-color: #ffffff;
    color: black;
}

.leftSide {
    width: 90% !important;
}

.sendBtn {
    max-width: 100%;
    cursor: pointer;
}

.chatLis {
    display: block;
    padding-inline-start: 0;
    list-style: none;
}

.chatLis li {
    display: block;
    margin-bottom: 16px;
    margin: 0 auto;
}

.chatLisDiv {
    position: relative;
    margin: 0 auto;
}

.helpIcon {
    z-index: 1000;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: block;
    background: #F7B500;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.16);
    border-radius: 50%;
    float: right;
    margin: 10px;
}

.helpIcon::after {
    content: '?';
    display: block;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    color: #785801;
    transform: translateY(7px);
}

.helping {
    background: #4E5373 !important;
}

.helping::after {
    color: #F7B500 !important;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


/* width */
ul.chatLis::-webkit-scrollbar {
    width: 10px;
}

/* Track */
ul.chatLis::-webkit-scrollbar-track {
    background:  #272a41;
}

/* Handle */
ul.chatLis::-webkit-scrollbar-thumb {
    background: #4f536d;
}

/* Handle on hover */
ul.chatLis::-webkit-scrollbar-thumb:hover {
    background:#f7b500;
}

ul.chatLis {
    scrollbar-color: #272a41 #4f536d;
}

.stickMe {
    position: fixed;
    width: 30%;
    box-shadow: 9px 0 48px 0 rgba(0, 0, 0, 0.14);
    background-color: #272a41;
    height: 100vh;
}

.change-link {
    cursor: pointer;
    text-decoration: underline;
}

.dropdownHeader, .regionListItem, .sourceListItem {
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
}

.dropdownHeader {
    padding-top: 25px;
    padding-bottom: 25px;
}

.dropdownList {
    list-style-type: none;
    padding: 0px;
}

.regionListItem {
    height: 56px;
    cursor: pointer;
}

.sourceListItem {
    height: 70px;
}

.regionListItem:hover {
    background-color: #F7B500;
    color: #000000;
}

.regionText {
    margin: 0;
    font-size: 16px;
}

.sourceContainer .source-info, .sourceContainer .source-logo {
    height: 100%;
    margin: 0;
}

.sourceContainer .source-logo {
    margin-right: 18px;
}

.sourceContainer .sourceListItem {
    padding-bottom: 10px;
    margin: 0;
}

.sourceContainer .custom-control-input {
    position: inherit;
}

.sourceSubmitBtn {
    height: 56px;
    width: 90%;
    background-color: #F7B500;
    font-weight: bold;
    font-size: 20px;
    border: 0;
    margin: auto;
    display: block;
    border-radius: 10px;
    color: #575757;
    margin-bottom: 20px;
}

.sourceContainer .custom-control-label::before {
    background-color: rgb(119, 119, 119) !important;
    border: 0 !important; 
}

.sourceContainer .custom-control-input:checked~.custom-control-label::before {
    background-color: rgba(247, 181, 0, 0.29) !important;
    border: 0 !important;
}
.sourceContainer .custom-switch .custom-control-label::after {
    background-color: #dddddd !important;
}

.sourceContainer .custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #F7B500 !important;
}

.mobile-menu {
    float: right;
    list-style: none;
    margin-right: 20px;
}

.mobile-menu li {
    float: right;
}

.mobile-menu .btn-region {
    background: rgba(247, 248, 250, 0.15);
    border-radius: 8px !important;
    color: #ffffff !important;
}

.dropdownHeader span {
    cursor: pointer;
}

.regionListItem:hover, .regionListItem:active, .regionListItem .active {
    background: #F7B500;
}