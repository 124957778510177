.drsBoxs {
    padding-right: 10px;
    overflow-y: auto;
    list-style: none;
    max-height: 100%;
}

.drsIntroDiv {
    padding-left: 50px;
    padding-top: 25px;
}

.drsDiv {
    padding-right: 0 !important;
    height: calc(100vh - 200px);
}

.drBox {
    position: relative;
    border: none;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015), -4px 0px 0px #D0D2DF;
    background-color: #ffffff;  
    float: left;
    margin-right: 19px;
    margin-left: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
}

.drBox.highLight {
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 0 7.1px 10px 0 rgba(0, 0, 0, 0.01), -4px 0 0 0 #ffb40d !important;
}

.drBox1 {
    padding: 20px 24px 10px 24px;
}

.drName {
    font-size: 16px;
    font-weight: 600;
    color: #474747;
    margin-bottom: 7px;
}

.drStreet {
    font-size: 13px;
    color: #575757;
    width: 144px;
    margin-bottom: 0;
}

.mapBox {
    width: 100%;
    height: 100%;
}

.moreInfoBtn {
    font-size: 14px !important;
    line-height: 124.84% !important;
    font-variant: small-caps !important;
    color: #346DFF !important;
    padding: 12px !important;
}

.doctorInfoModal {
    width: 50% !important;
    height: 55% !important;
}

.drModalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #474747;
    margin: 10px;
}

.drModalTabs {
    list-style: none;
    font-size: 14px;
    line-height: 17px;
    font-variant: small-caps;
    color: #000000;
    border-bottom: 1px solid #E2E2E2;
    height: 28px;
    padding-left: 0;
    margin-left: 10px;
    margin-top: 30px;
}

.drModalTabs li {
    float: left;
    margin-right: 23px;
    padding-bottom: 9px;
    cursor: pointer;
}

.drModalTabs li.active {
    border-bottom: 3px solid #F7B500;
}

.drModalDetail {
    margin-top: 15px;
    margin-left: 10px;
}

.drBoxNumber {
    position: absolute;
    left: -30px;
    display: block;
    width: 25px;
    height: 25px;
    color: #ffffff;
    background-color: #272a41;
    border: none;
    border-radius: 50%;
    text-align: center;
}

.drBox.highLight .drBoxNumber {
    background-color: #ffb40d;
    color: #000000;
}