.loginpage {
    height: 1024px;
    background-color: #f7f8fa;
}

.login-header {
    width: 100%;
    height: 90px;
    box-shadow: 0 1.7px 2.2px 0 rgba(125, 125, 125, 0.02), 0 4px 5.3px 0 rgba(125, 125, 125, 0.03), 0 7.5px 10px 0 rgba(125, 125, 125, 0.04), 0 13.4px 17.9px 0 rgba(125, 125, 125, 0.04);
    background-color: #ffffff;
}

.login-header .Logo {
    width: 96px;
    height: 34px;
    object-fit: contain;  
}

.login-form {
    max-width: 431px;
    border-radius: 10px;
    box-shadow: 0 1.6px 2.2px 0 rgba(0, 0, 0, 0.01), 0 3.8px 5.3px 0 rgba(0, 0, 0, 0.01), 0 7.1px 10px 0 rgba(0, 0, 0, 0.01);
    background-color: #ffffff; 
    margin: 4% auto auto auto;
}

.loginform-title {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.55;
    color: #575757;  
}

.login-divider {
    height: 1px;
    color: #e2e2e2;
    margin: 0;
}

.login-formcontrol {
    width: 352px;
    height: 56px;
    border-radius: 10px;
    background-color: #ededed !important;  
    border: none;
    display: block;
    margin: auto;
    font-size: 20px;
    line-height: 1.55;
    color: #575757 !important;
    padding-left: 27px;
}

.login-checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-size: 16px;
    line-height: 1.55;
    color: #575757;  
}


.login-checkbox-container input[type=checkbox] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    width: 19px;
    height: 19px;
    border-radius: 4px;
    border: solid 2px #575757;
}
  
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
  
.login-checkbox-container input:checked ~ .checkmark {
    background-color: #575757;
}

.login-checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
  
.login-checkbox-container .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.loginbtn {
    width: 352px;
    height: 56px;
    border-radius: 10px;
    border: none;
    background-color: #ffb40d;

    font-size: 20px;
    font-weight: bold;
    line-height: 1.55;
    color: #575757;  
    text-align: center;

    display: block;
    margin: auto;
}

.formlegal {
    padding: 0 40px 20px 40px;
}

.formlegal p, .formlegal span {
    font-size: 16px;
    line-height: 1.55;
    color: #575757;
}

.formlegal p b, .formlegal span strong {
    font-weight: bold;
}

.login-form .fieldError {
    width: 352px;
    margin: auto;
    color: red;
}

.login-form .hasError {
    border: 1px solid red !important;
}