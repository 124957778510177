.resultArea {
    width: 70%;
    float: right;
    background-color: #f7f8fa;
}

.resultHeader {
    height: 90px;
    box-shadow: 0 1.7px 2.2px 0 rgba(125, 125, 125, 0.02), 0 4px 5.3px 0 rgba(125, 125, 125, 0.03), 0 7.5px 10px 0 rgba(125, 125, 125, 0.04), 0 13.4px 17.9px 0 rgba(125, 125, 125, 0.04);
    background-color: #ffffff;
}

.resultLogo {
    margin-top: 28px;
    margin-left: 68px;
    width: 96px;
    height: 34px;  
}

.logOut {
    height: 21px;
    font-size: 18px;
    cursor: pointer;
    float: right;
    margin-right: 63px;
    margin-top: 34px;
}

.logOut a {
    font-weight: bold;
    color: #575862;
}

.circleUser {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #9e71ff;
    border: none;
    border-radius: 50%;
    margin-right: 6px;
    transform: translateY(2px);
    -webkit-transform: -webkit-translateY(4px);
}

.userBox {
    float: right;
    margin-right: 3%;
    margin-top: 34px;
}

.userName {
    height: 25px;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #575862;
    border: none;
    background: none;
}

.userName:focus {
    outline: none;
}

.resourcesMenuItem .dropdown-toggle::after { 
    content: none; 
} 

.resourcesMenuItem .dropdown-header {
    color: black;
    font-weight: bolder;
}

.resourcesMenuItem .dropdown-menu {
    box-shadow: 0px 0px 20px rgba(211, 211, 211, 0.781);
}

.resourcesMenuItem .source-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: default;
    padding: 6px;
}

.resourcesMenuItem .source-logo {
    height: 70px;
    width: 70px;
    margin-right: 12px;
}

.source-info {
    display: flex;
    margin: 8px 12px;
    align-items: center;
}

.sources-menu {
    max-height: 250px;
    min-width: 350px;
    overflow-y: scroll;
}