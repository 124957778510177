.answerBubble {
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #fcfcfc;  
    padding: 19px;
    animation: fadeUp 0.5s;
}

.answerBubble div {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
}

.answerBubbleImage {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    position: absolute;
    left: -50px;
    bottom: 0;
}

.region-badge-container-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    position: absolute;
    top: -30px;
    left: 0px;
    right: 0px;
    margin: auto;
    background-color: white;
    border-radius: 32px;
    box-shadow: 0px 13px 24px rgba(0, 0, 0, 0.1);
}

.region-badge-mobile {
    height: 32px;
    width: 32px;
}

@keyframes fadeUp {
    0% {
        transform: translateY(100px);
        -webkit-transform: -webkit-translateY(100px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        -webkit-transform: -webkit-translateY(0);
        opacity: 1;
    }
}