.diAnswerDiv {
    background: #FFFFFF;
    box-shadow: 0px 1.57734px 2.21381px rgba(0, 0, 0, 0.00843437), 0px 3.79056px 5.32008px rgba(0, 0, 0, 0.0121168), 0px 7.13728px 10.0172px rgba(0, 0, 0, 0.015);
    border-radius: 10px;
    margin-bottom: 15px; 
}

.diAnswer {
    padding: 28px 28px 0 28px;
}

.diAnswerLabel {
    font-weight: 600;
    font-size: 12px;
    line-height: 169.34%;
    font-variant: small-caps;
    color: #FFFFFF;
    border-radius: 50px;
    display: inline-block;
    padding: 0px 10px;
}

.diAnswerTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 169.34%;
    color: #474747;
    margin-bottom: 10px;
}

.diAnswerEx {
    font-size: 14px;
    line-height: 130.34%;
    color: #474747;
    margin-bottom: 10px;
}

.diHr {
    color: #E2E2E2;
}

.diAnswer .btn {
    font-size: 14px !important;
    line-height: 124.84% !important;
    font-variant: small-caps !important;
    color: #346DFF !important;
    padding: 0;
}

.diAnswerDiv .diSource {
    color: #575757;
    font-weight: 600;
    font-size: 14px;
    line-height: 124.84%;
    margin-left: 28px;
    padding-bottom: 16px;
}

.diDiv {
    padding-left: 50px;
    padding-right: 50px;
}

.diRefs {
    font-size: 16px;
}