.tagsBubble {
    margin: 0 auto;
    animation: fadeUp 0.5s;
}

.tagBubble {
    min-height: 31px;
    border-radius: 50px;
    border: none;
    box-shadow: 0 14px 14px 0 rgba(0, 0, 0, 0.16);
    background-color: #4f536d;
    margin-right: 9px;
    margin-bottom: 9px;
    padding: 4px 16px;
    font-size: 14px;
    color: #ffffff;  
    cursor: pointer;
    float: left;
}

.tagBubble .colorStyle {
    color: #f7b500;
}

@keyframes fadeDown {
    0% {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: -webkit-translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(200px);
        -webkit-transform: -webkit-translateY(200px);
    }
}