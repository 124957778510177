.regionPage {
    width: 100%;
    height: 100vh;
    background-color: #272A41;
}

.regionPage .tali-photo-big {
    margin-bottom: 30px;
}

.regionPage .tali-intro {
    margin-bottom: 39px;
}

.regionBox {
    width: 400px;
    display: block;
    margin: auto;
    margin-top: 5%;
}

@media only screen and (max-width: 540px) {
    .regionBox {
        width: 95%;
    }
}

.regionPageLogo {
    margin-top: 1%;
    margin-left: 2%;
}

.regionSelectInput {
    width: 100%;
    background: #F7F7FA;
    border: 1px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    border-radius: 8px;
    
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #272A41;
    padding: 10px 16px;

    /* reset */
    -webkit-appearance: none;
    -moz-appearance: none;

    /* add arrow back */
    background-image: url('../../images/dropdown-arrow.png');
    background-position: calc(100% - 16px) center;
    background-size: 16px 9px;
    background-repeat: no-repeat;
}

.regionSelectInput option:hover {
    background-color: #FFB40D;
}

.regionPageText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.85);

    margin-top: 15px;
    margin-bottom: 60px;
}

.saveRegionBtn {
    background: #FFB40D;
    border-radius: 10px;
    border: none;
    width: 100%;

    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 154.84%;
    text-align: center;
    color: #575757;
    padding: 12px;

    margin-bottom: 30px;
}

.regionSkip {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}