@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css');

*:focus {
    outline: none;
}

html, body {
    background-color: #272a41;
}

.row {
    margin: 0;
    padding: 0;
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 19px;
}

h4 {
    font-size: 18px;
}