.resend-txt {
    font-size: 20px;
    line-height: 154.84%;
    color: #575757;
    padding: 20px 30px 0px 30px;
} 

.resend-txt-2 {
    font-size: 16px;
    line-height: 154.84%;
    color: #575757;
    padding: 10px 30px 30px 30px;
} 

.resend-txt-link {
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
}